interface Breakpoints extends Array<string> {
  sm: string
  md: string
  lg: string
}

interface FontWeights extends Array<number> {
  normal: number
  medium: number
  semiBold: number
}

const fontSizes = {
  headerXl: '2.75rem',
  header1: '2rem',
  header2: '1.73rem',
  header3: '1.25rem',
  header4: '1.1rem',
  lede: '1.15rem',
  body: '1rem',
  small: '0.85rem',
  extraSmall: '0.7rem',
} as const

const breakpoints: Breakpoints = ['375px', '768px', '1050px'] as any
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]

const fontWeights: FontWeights = [400, 500, 600] as any
fontWeights.normal = fontWeights[0]
fontWeights.medium = fontWeights[1]
fontWeights.semiBold = fontWeights[2]

const colors = {
  // Core
  primary: '#021546',
  white: '#FFFFFF',
  black: '#000000',

  // Functional greys
  grey1: '#F2F3F6',
  grey2: '#D9DCE3',
  grey3: '#B3B9C7',
  grey4: '#7F879D',
  grey5: '#606A81',
  grey6: '#465169',

  // Functional non-grey
  blue: '#3446e0',
  green: '#0DD1AF',
  red: '#DD1D56',
  magenta: '#FE04C9',
  caribbeanGreen: '#09BD9E',

  jobLaunchYellow: '#FDF05F',
  visitedLink: '#8C59F4',
} as const

const colorAliases = {
  link: colors.blue,
  text: colors.primary,
} as const

const space = [
  /*  0 */ '0rem',
  /*  1 */ '0.4rem',
  /*  2 */ '0.75rem',
  /*  3 */ '1rem',
  /*  4 */ '1.25rem',
  /*  5 */ '1.75rem',
  /*  6 */ '2rem',
  /*  7 */ '2.75rem',
  /*  8 */ '3.5rem',
  /*  9 */ '4.5rem',
  /* 10 */ '6rem',
]

export const theme = {
  space,
  colors: {
    ...colors,
    ...colorAliases,
  },
  fontSizes,
  fonts: {
    sans: '"Work Sans", sans-serif',
  },
  fontWeights,
  breakpoints,
}

export type Theme = typeof theme
